/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// @import TailwindCSS

import './src/styles/index.css';

// You can delete this file if you're not using it

export const onClientEntry = () => {
  var introAnimation = setInterval(function () {
    var loaderSvgFill = document.getElementById('___loaderSvgFill');
    var logoFillArray = ['#F48B74', '#d4739d', '#a3894a', '#dc8a74', '#6c7dab'];
    var loader = document.getElementById('___loader');
    if (loader.style.display === 'none') {
      clearInterval(introAnimation);
    }
    loaderSvgFill.style.fill = logoFillArray[Math.floor(Math.random() * Math.floor(4))];
  }, 100);
  // console.log('entry');
};

// export const onRouteUpdate = () => {
//   console.log('route');
// };

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  const { pathname } = location;
  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = [`/`];
  var html = document.querySelector('html');
  // if the new route is part of the list above, scroll to top (0, 0)
  if (scrollToTopRoutes.indexOf(pathname) !== -1) {
    html.scrollTo(0, 0);
  }
  return false;
};

export const onInitialClientRender = () => {
  // Scroll halt + force scroll to top
  var html = document.querySelector('html');

  var scrollTimer = setInterval(enableScroll, 1500);
  function enableScroll() {
    html.style.overflowY = 'scroll';
    clearTimeout(scrollTimer);
  }
  // Animations
  setTimeout(function () {
    var loaderWrapper = document.getElementById('___loaderWrapper');
    loaderWrapper.classList.add('fade-out');
  }, 500);
  setTimeout(function () {
    var loader = document.getElementById('___loader');
    loader.classList.add('fade-out');
  }, 1300);
  setTimeout(function () {
    var loader = document.getElementById('___loader');
    loader.style.display = 'none';
  }, 2000);
};
