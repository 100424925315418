import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Styles } from 'styles/base';
import { Helmet } from 'react-helmet';
import { helmet } from '../../utils/helmet';

import { Devtools } from 'components/devtools/Devtools';

interface AppLayoutProps {
  children: ReactNode;
}

const isDev = process.env.NODE_ENV === 'development';

const Layout = styled.div`
  display: block;
  min-height: 100vh;
  background: #ffffff;
`;

export default ({ children }: AppLayoutProps) => (
  <>
    <Styles />

    <Layout>
      <Helmet {...helmet} />
      {children}

      {isDev && <Devtools />}
    </Layout>
  </>
);
